type PatchAccountConsentBody = {
  isAccepted: boolean;
  version: string;
};

type PatchAccountConsentOptions = {
  accountId: string;
  baseUrl: string;
  body: PatchAccountConsentBody;
  purposeId: string;
  token: string;
};

export function patchAccountConsent({
  accountId,
  baseUrl,
  body,
  purposeId,
  token,
}: PatchAccountConsentOptions): Promise<{ success: boolean }> {
  return fetch(`${baseUrl}/account/${accountId}/consent/${purposeId}`, {
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(response => response.json());
}
