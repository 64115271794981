export function extractLanguageFromLocale(locale: string) {
  try {
    return new Intl.Locale(locale).language;
  } catch (error) {
    // invalid locale
    if (error instanceof RangeError) {
      return null;
    }
    throw error;
  }
}
