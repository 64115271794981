import { useState, useEffect } from 'react';

import type { CiamIdentity } from '@hubcms/domain-ciam';
import { log } from '@hubcms/utils-monitoring';
import { enableGoogleShowcase } from '@hubcms/brand';

export const useCiamData = (clientId: string) => {
  const [ciamUser, setCiamUser] = useState<CiamIdentity | null>(null);
  const [authorizationUrl, setAuthorizationUrl] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getAccessToken = () => (typeof window !== 'undefined' ? window.CIAM.getAccessToken(clientId) : null);
  const sendEmailVerification = () => window.CIAM.sendEmailVerification(clientId);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!window.CIAM) {
        log('CIAM not available');
        setIsLoading(false);
        return;
      }

      setIsAuthenticated(window.CIAM.isAuthenticated(clientId));

      try {
        const userInfoPromise = window.CIAM.getUserInfo(clientId);
        const loginUrlPromise = enableGoogleShowcase
          ? window.CIAM.getLoginUrl({
              clientId,
              options: { returnTo: `${encodeURIComponent(window.location.href)}`, connection: 'mh-google-social' },
              identityLevel: '',
              tracking: {},
            })
          : Promise.resolve(null);

        const [userInfoResult, loginUrlResult] = await Promise.allSettled([userInfoPromise, loginUrlPromise]);

        if (userInfoResult.status === 'fulfilled') {
          setCiamUser(userInfoResult.value);
        } else {
          log('Failed to get user info from CIAM', userInfoResult.reason);
        }

        if (enableGoogleShowcase && loginUrlResult.status === 'fulfilled' && loginUrlResult.value) {
          setAuthorizationUrl(loginUrlResult.value);
        } else if (enableGoogleShowcase && loginUrlResult.status === 'rejected') {
          log('Failed to get login URL from CIAM', loginUrlResult.reason);
        }
      } catch (e) {
        log('Failed to get user info from CIAM', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [clientId]);

  return { ciamUser, isAuthenticated, isLoading, getAccessToken, sendEmailVerification, authorizationUrl };
};
