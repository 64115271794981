import type { AccountConsent } from '@hubcms/domain-account-consent';

type FetchAccountConsentOptions = {
  accountId: string;
  baseUrl: string;
  purposeId: string;
  token: string;
};

export function fetchAccountConsent({
  accountId,
  baseUrl,
  purposeId,
  token,
}: FetchAccountConsentOptions): Promise<AccountConsent> {
  return fetch(`${baseUrl}/account/${accountId}/consent/${purposeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
}
