import Cookies from 'js-cookie';

import type { AccountConsentStatus } from '@hubcms/domain-account-consent';

import { createAccountConsentCookieName } from './create-account-consent-cookie-name';

export function setAccountConsentCookie(purposeId: string, accountId: string, accountConsentStatus: AccountConsentStatus) {
  const expiresInDays = accountConsentStatus === 'ToBeRequested' ? 3 : 365;

  Cookies.set(createAccountConsentCookieName(purposeId, accountId), accountConsentStatus, {
    expires: expiresInDays,
  });
}
