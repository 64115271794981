import getConfig from 'next/config';

import { accountConsentConfig as accountConsentBrandConfig } from '@hubcms/brand';
import type { AccountConsentConfig, AccountConsentEnvConfig } from '@hubcms/domain-account-consent';

const { publicRuntimeConfig } = getConfig();

const accountConsentEnvConfig: Partial<AccountConsentEnvConfig> = {
  apiBaseUrl: publicRuntimeConfig.ACCOUNT_CONSENT_API_URL,
  auth0ClientId: publicRuntimeConfig.AUTH0_CLIENT_ID,
  purposeId: publicRuntimeConfig.ACCOUNT_CONSENT_PURPOSE_ID,
};

export const accountConsentConfig: Partial<AccountConsentConfig> = {
  ...accountConsentBrandConfig,
  ...accountConsentEnvConfig,
};

export function isValidAccountConsentConfig(config: Partial<AccountConsentConfig>): config is AccountConsentConfig {
  return Object.values(config).every(configValue => Boolean(configValue));
}
