import { useCallback, useEffect, useState } from 'react';
import type { AccountConsentStatus } from '@hubcms/domain-account-consent';
import { getAccountConsentCookie, setAccountConsentCookie } from '@hubcms/utils-account-consent';
import { error } from '@hubcms/utils-monitoring';

import { accountConsentConfig, isValidAccountConsentConfig } from './config';
import { patchAccountConsent } from './patch-account-consent';
import { fetchAccountConsent } from './fetch-account-consent';

type UseAccountConsentReturnType = {
  isAccountConsentLoaded: boolean;
  accountConsentStatus: AccountConsentStatus | null;
  updateAccountConsent: (accountId: string, purposeCopyVersion: string, isAccepted: boolean) => void;
};

export function useAccountConsent(accountId: string | null, accessToken: string | null): UseAccountConsentReturnType {
  const [isAccountConsentLoaded, setIsAccountConsentLoaded] = useState(false);
  const [accountConsentStatus, setAccountConsentStatus] = useState<UseAccountConsentReturnType['accountConsentStatus']>(null);

  useEffect(() => {
    const isUserDefined = !!accessToken;
    if (!isUserDefined || !isValidAccountConsentConfig(accountConsentConfig)) {
      setIsAccountConsentLoaded(true);
      return;
    }
    if (!accountId) {
      // there's an access token but no account id, so we can't fetch the account consent status
      return;
    }
    // first, try to get the account consent status from the cookie
    const cookieValue = getAccountConsentCookie(accountConsentConfig.purposeId, accountId);
    if (cookieValue) {
      setIsAccountConsentLoaded(true);
      setAccountConsentStatus(cookieValue);
      return;
    }
    // cookie not found, fetch the account consent status from the API
    const fetchAccountConsentStatus = async () => {
      if (!isValidAccountConsentConfig(accountConsentConfig)) {
        return;
      }
      try {
        const accountConsentFetchResult = await fetchAccountConsent({
          accountId,
          baseUrl: accountConsentConfig.apiBaseUrl,
          purposeId: accountConsentConfig.purposeId,
          token: accessToken,
        });

        // cache the account consent status in the cookie
        // and update the state
        if (isValidAccountConsentConfig(accountConsentConfig)) {
          setAccountConsentStatus(accountConsentFetchResult.status);
          setAccountConsentCookie(accountConsentConfig.purposeId, accountId, accountConsentFetchResult.status);
        }
      } catch (err) {
        if (err.status === 'rejected') {
          error(`Failed to fetch account consent for account ${accountId} with purpose ${accountConsentConfig.purposeId}`);
        }
      } finally {
        setIsAccountConsentLoaded(true);
      }
    };

    fetchAccountConsentStatus();
  }, [accessToken, accountId]);

  // callback to update the account consent status from e.g. the account consent dialog
  const updateAccountConsent = useCallback(
    async (accountId: string, purposeCopyVersion: string, isAccepted: boolean) => {
      if (!accountId || !accessToken || !purposeCopyVersion) {
        return;
      }
      try {
        if (!isValidAccountConsentConfig(accountConsentConfig)) {
          throw new Error('Invalid account consent config');
        }
        const response = await patchAccountConsent({
          accountId,
          baseUrl: accountConsentConfig.apiBaseUrl,
          body: { isAccepted, version: purposeCopyVersion },
          purposeId: accountConsentConfig.purposeId,
          token: accessToken,
        });

        if (response.success) {
          const consentStatus = isAccepted ? 'Accepted' : 'Declined';

          setAccountConsentStatus(consentStatus);
          setAccountConsentCookie(accountConsentConfig.purposeId, accountId, consentStatus);
        }
      } catch {
        error(`Failed to patch account consent for account ${accountId} with purpose ${accountConsentConfig.purposeId}`);
      }
    },
    [accessToken],
  );

  return { isAccountConsentLoaded, accountConsentStatus, updateAccountConsent };
}
