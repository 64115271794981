import Cookies from 'js-cookie';

import { type AccountConsentStatus } from '@hubcms/domain-account-consent';

import { createAccountConsentCookieName } from './create-account-consent-cookie-name';
import { isAccountConsentStatus } from './is-account-consent-status';

export function getAccountConsentCookie(purposeId: string, accountId: string): AccountConsentStatus | null {
  const cookieName = createAccountConsentCookieName(purposeId, accountId);
  const cookieValue = Cookies.get(cookieName) ?? '';

  if (isAccountConsentStatus(cookieValue)) {
    return cookieValue;
  }
  Cookies.remove(cookieName);

  return null;
}
